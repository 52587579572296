import axios from '@axios'

export default {
  namespaced: true,
  state: {
    visitors: []
  },
  getters: {
    visitors: state => {
      return state.visitors
    }
  },
  mutations: {
    visitors(ctx, visitors) {
      ctx.visitors = visitors
    }
  },
  actions: {
    fetchVisitors(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('https://reports.renovaremcristo.org.br/renovar-reports/api/executor', {
          "key": "DADOS_NOVOS_VISITANTES",
          "params": params
        })
        .then(function(response) {
          ctx.commit('visitors', response.data.data)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    }
  },
}
