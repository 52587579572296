<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="mt-2 ml-2">
      <b-row>
        <b-col cols="12">
          <h2>Visitantes</h2>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <div class="d-flex align-items-center justify-content-end">
            <date-picker
              v-model="dtFiltro"
              range
              format="DD/MM/YYYY"
              lang="pt-br"
              class="mr-1"
            ></date-picker>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
            />
          
            <vue-json-to-csv 
              :json-data="teste"
              :labels='{
                "Nome": { title: "Nome" },
                "E-mail": { title: "E-mail"},
                "Telefone": { title: "Telefone" },
                "Nome do PG": { title: "Nome do PG"},
              }'
              csv-title="Visitantes"
              separator=";"
              >
              <b-button variant="primary">
                Download
              </b-button>
            </vue-json-to-csv>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      :items="filterVisitor(teste)"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      stacked="sm"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(Telefone)="data">
        {{ mtel(data.value) }}
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted"
            >Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de
            {{ dataMeta.of }} visitantes</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import VueJsonToCsv from 'vue-json-to-csv';

import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import {
  ref,
  reactive,
  onUnmounted,
  computed,
  watch,
} from "@vue/composition-api";
import store from "@/store";

import visitorStoreModule from "./visitorsStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    DatePicker,
    vSelect,
    VueJsonToCsv
  },
  setup() {
    try {
      const INVOICE_APP_STORE_MODULE_NAME = "appvisitors";

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.registerModule(INVOICE_APP_STORE_MODULE_NAME, visitorStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
          store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
      });

      const teste = computed(() => store.state.appvisitors.visitors);
      const totalInvoices = computed(
        () => store.state.appvisitors.visitors.length
      );

      const dataMeta = computed(() => {
        return {
          from: perPage.value * currentPage.value + 1 - perPage.value,
          to:
            perPage.value * currentPage.value > totalInvoices.value
              ? totalInvoices.value
              : perPage.value * currentPage.value,
          of: totalInvoices.value,
        };
      });

      const tableColumns = [
        { key: "Nome", label: "Nome", sortable: true },
        { key: "E-mail", label: "E-mail", sortable: true },
        { key: "Telefone", label: "Telefone", sortable: true },
        { key: "Nome do PG", label: "Nome do PG", sortable: true },
      ];

      function getLastSunday(d) {
        let t = new Date(d);
        t.setDate(t.getDate() - t.getDay());
        return t;
      }

      function mtel(tel) {
        tel = tel.replace(/\D/g, "");
        tel = tel.replace(/^(\d)/, "+$1");
        tel = tel.replace(/(.{3})(\d)/, "$1 ($2");
        tel = tel.replace(/(.{7})(\d)/, "$1) $2");
        if (tel.length == 12) {
          tel = tel.replace(/(.{1})$/, "-$1");
        } else if (tel.length == 13) {
          tel = tel.replace(/(.{2})$/, "-$1");
        } else if (tel.length == 14) {
          tel = tel.replace(/(.{3})$/, "-$1");
        } else if (tel.length == 15) {
          tel = tel.replace(/(.{4})$/, "-$1");
        } else if (tel.length > 15) {
          tel = tel.replace(/(.{4})$/, "-$1");
        }
        return tel;
      }

      let dtIni = getLastSunday(new Date());
      let dtFim = getLastSunday(new Date());
      dtFim.setDate(dtFim.getDate() + 7);

      const perPage = ref(10);
      const currentPage = ref(1);
      const perPageOptions = [10, 25, 50, 100];
      const searchQuery = ref("");
      const sortBy = ref("id");
      const isSortDirDesc = ref(true);
      const statusFilter = ref(null);
      const dtFiltro = ref([dtIni, dtFim]);

      function filterVisitor(visitors) {
        if (searchQuery.value != null) {
          visitors = visitors.filter(
            (word) =>
              word["Nome do PG"].toLowerCase().includes(searchQuery.value) ||
              word["Nome"].toLowerCase().includes(searchQuery.value) ||
              word["Telefone"].toLowerCase().includes(searchQuery.value) ||
              word["E-mail"].toLowerCase().includes(searchQuery.value)
          );
        }

        return visitors;
      }

      const fetchVisitors = (dtIni, dtFim) => {
        let params = {
          DT_INI: dtIni.toLocaleDateString("en-CA"),
          DT_FIM: dtFim.toLocaleDateString("en-CA"),
        };
        store.dispatch("appvisitors/fetchVisitors", params);
      };

      fetchVisitors(dtIni, dtFim);

      watch(
        () => dtFiltro,
        (newValue, oldValue) => {
          fetchVisitors(newValue.value[0], newValue.value[1]);
        },
        { deep: true }
      );

      return {
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,

        statusFilter,
        dtFiltro,

        avatarText,
        teste,
        fetchVisitors,
        filterVisitor,
        mtel,
      };
    } catch (ex) {
      console.log(ex);
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
